.App {
  display: flex;
  overflow: hidden;
  color: var(--black);
  background: #f3f3f3;
  /* font-family: "Roboto", sans-serif; */
  /* background: #000; */
}

.body_container {
  /* overflow: hidden; */
  height: 100vh;
  flex: 1;
  margin: 0 10px 0;
}
.body__wrapper {
  height: 87%;
  margin-top: 10px;
  overflow-y: auto;
  margin-bottom: 10px;
  border-radius: 10px;
}
.sidebar_container {
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.error {
  font-size: 12px;
  color: #e15a3c;
}

.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: rgba(102, 211, 221, 0.867);
  /* background: var(--buttonBg); */
  filter: blur(72px);
}

.card {
  /* background: rgba(255, 255, 255, 0.5); */
  box-shadow: 0 8px 32px 0 rgba(119, 122, 164, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
}
.title {
  color: gray;
  font-weight: 700;
}
.subtitle {
  color: gray;
  font-weight: 600;
}

input,
select {
  border: none;
  outline: none;
  background-color: var(--inputColor);
  border-radius: 8px;
  padding: 10px;
  flex: 1;
}
.dark input,
select {
  background-color: var(--gray);
}

.primaryButton {
  background-image: linear-gradient(
    to right,
    #02aab0 0%,
    #00cdac 51%,
    #02aab0 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 10px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.primaryButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

button:disabled {
  /* height: 2rem; */
  background: silver;
  pointer-events: none;
  cursor: wait;
}

.removeButton {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.removeButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.removeButton:disabled {
  background: silver;
  pointer-events: none;
  cursor: wait;
}

.warningButton {
  background-image: linear-gradient(
    to right,
    #ff8008 0%,
    #ffc837 51%,
    #ff8008 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.warningButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.warningButton:disabled {
  background: silver;
  pointer-events: none;
  cursor: wait;
}

.updateButton {
  background-image: linear-gradient(
    to right,
    #f09819 0%,
    #edde5d 51%,
    #f09819 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.updateButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  color: gray;
  margin-top: 18px;
  padding: 10px;
  height: 50px;
  align-items: center;
}

/* Scrollbar design start */

::-webkit-scrollbar {
  /* display: none;  */
  width: 5px;
  height: 5px;
  cursor: auto;
}

::-webkit-scrollbar-track {
  /* background-color: darkgrey; */
}

::-webkit-scrollbar-thumb {
  background-image: var(--buttonBg);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
/* Scrollbar design end */

.ag-root-wrapper {
  border-color: transparent !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.spinner {
  margin: auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  /* background-color: rgb(255, 255, 255); */
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 18%,
    rgba(96, 96, 96, 1) 80%
  );

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .body_container {
    height: calc(100vh - 50px);
  }
  .table__wrapper {
    z-index: 1;
  }
  .sidebar_container {
    z-index: 999;
  }
}
