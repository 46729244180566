@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --bgDark: #232736;
  --bgLight: #d4d4d4;
  --red: #f92525;
  --btnHoverColor: #5bc8ab;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  /* --buttonBg: linear-gradient(98.63deg, #f9a225 0%, #f95f35 100%); */
  --buttonBg: linear-gradient(98.63deg, #5bc8ab 0%, #3cb7ca 100%);
  --removeButtonBg: linear-gradient(98.63deg, #e15a3c 0%, #d86c3a 100%);
  --warngingButton: linear-gradient(98.63deg, #b0f314 0%, #cdd620 100%);
  --warningHoverColor: #e5ee41;
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4cb256;
  --video: #4a4eb7;
  --location: #ef5757;
  --shedule: #e1ae4a;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  background: var(--bgLight);
}
.dark body {
  background: var(--bgDark);
}

/********************************************************** popup Modal design start  ***********************************************************/
.modal__Body {
  background: rgba(9, 10, 27, 0.8);
}
.modal__title {
  color: aliceblue;
  font-size: 12px;
  font-weight: bold;
  padding: 0 20px;
}
.modal__close {
  background: rgb(255, 255, 255);
  border-radius: 50%;
}
.modal__close:hover {
  color: rgb(153, 0, 0);
}

/* Dark mode */
.dark .modal__Body {
  background: rgba(9, 10, 27, 0.5);
}
